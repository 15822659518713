<div class="row p_i_header center-xs center-s center-m center-l center-xl" [ngClass]="{ 'orange': themeColor==='orange', 'blue': themeColor==='blue', 'yellow': themeColor==='yellow' }">
    <div class="col-xs col-s col-m-2 col-l-2 col-xl-2"> </div>
    <div class="col-xs-8 col-s-8 col-m=8 col-l-8 col-xl-8">
        <h1>{{ pageTitle }}</h1>
        <ng-content select="[header]"></ng-content>
    </div>
    <div class="col-xs col-s col-m-2 col-l-2 col-xl-2"> </div>
</div>
<div class="row project-info center-xs center-s center-m center-l center-xl" [ngClass]="{ 'orange': themeColor==='orange', 'blue': themeColor==='blue', 'yellow': themeColor==='yellow' }">
    <div class="col-xs col-s col-m-2 col-l-2 col-xl-2"> </div>
    <div class="col-xs-8 col-s-8 col-m=8 col-l-8 col-xl-8">
        <div class="row">
            <div class="col-xs col-s col-m-2 col-l-2 col-xl-2"> </div>
            <div class="col-xs-12 col-s-12 col-m=4 col-l-4 col-xl-4">
                <h2>Details</h2>
                <ng-content select="[details]"></ng-content>
            </div>
            <div class="col-xs-12 col-s-12 col-m-4 col-l-4 col-xl-4">
                <h2>My Role</h2>
                <ng-content select="[role]"></ng-content>
            </div>
            <div class="col-xs col-s col-m-2 col-l-2 col-xl-2"> </div>
        </div>
    </div>
    <div class="col-xs col-s col-m col-l col-xl"> </div>
</div>