<router-outlet (activate)="onActivate($event)"></router-outlet>

<!-- Universal Footer-->
<div class="row footer">
    <div class="col-xs-1 col-s-1 col-m-2 col-l-2 col-xl-2"></div>
    <div class="col-xs-10 col-s-10 col-m-8 col-l-8 col-xl-8"> 
        <div class="row">
            <div class="col-xs-4 col-s-4 col-m-3 col-l-3 col-xl-3">
                <div class="footer-copy">
                    <a [routerLink]="'/contact'">Contact</a>
                    <a [routerLink]="'/resume'">Résumé</a>
                    <a [routerLink]="'/testimonials'">Testimonials</a>
                    <a [routerLink]="'/caseStudies'">Case Studies</a>
                    <a [routerLink]="'/home'">Home</a>
                </div>
            </div>
            <div class="col-xs-4 col-s-4 col-m-3 col-l-3 col-xl-3">
            </div>
            <div class="col-xs col-s col-m-3 col-l-3 col-xl-3">
            </div>
            <div class="col-xs-4 col-s-4 col-m-3 col-l-3 col-xl-3">
                <div class="footer-copy">
                    <h4><b>Social</b></h4>
                    <a href="https://www.linkedin.com/in/wersmith/" target="_blank"><i class="fa fa-linkedin"></i></a>
                    <br /><br /><br />
                    <h4><b>Location</b></h4>
                    Carmel, Indiana
                    <br />
                    wesley.smith@betasigmapsi.org
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-s-12 col-m-12 col-l-12 col-xl-12">
            Copyright © Wesley Smith 2014-2020. All Rights Reserved.
            <br /><br /> <br />
        </div>
    </div>
    <div class="col-xs-1 col-s-1 col-m-2 col-l-2 col-xl-2"></div>
</div>