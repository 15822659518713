<div class="row">
    <div class="col-xs col-s col-m-2 col-l-2 col-xl-2"></div>
    <div class="col-xs-10 col-s-10 col-m-8 col-l-8 col-xl-8 fullCard">
        <div class="middle-xs middle-s middle-m middle-l middle-xl">
            <div class="bodyHeader">
                <div [ngClass]="{ 'orange': themeColor==='orange', 'blue': themeColor==='blue', 'yellow': themeColor==='yellow' }">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs col-s col-m-2 col-l-2 col-xl-2"></div>
</div>
