<app-navigation [activeLink]="'resume'"></app-navigation>

<app-page-header [pageTitle]="'My Work History'" [subHead]="''" [sectionImage]="true">
    As a UX Design Leader, my work has focused on the implementation of UX systems and process which enable new design teams to perform with a consistent approach. I'm skilled at bringing together UX and development team members to deliver a best-in-class product.
    <br/> <br/>
    I've led or co-lead the creation of two design systems. The Lilly Design System and the Redline Design System.
    <br/> <br/>
    I establish scalable and standardized research techniques to my teams. In two separate jobs, I've introduced new UX research techniques geared towards statistical and experimental validity.
    <br /> <br />
    <a href="/assets/images/pdf/Wesley_Smith_Resume.pdf" download>
        <button class="btn-primary blue" ><i class="fa fa-download"></i>&nbsp;Download My Resume </button>
    </a>
</app-page-header>