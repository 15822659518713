import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-persona-development',
  templateUrl: './user-persona-development.component.html',
  styleUrls: ['./user-persona-development.component.scss']
})
export class UserPersonaDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
