<nav>
        <div class="row">
                <div class="col-xs-1
                col-s-1
                col-m-2
                col-l-2
                col-xl-2"> </div>
                <div class="col-xs-1 col-xs-1 col-m-1 col-l-1 col-xl-1 logo"> 
                        <img src="../../assets/images/system_level_svg/logo.svg">
                </div>
                <div class="col-xs-10 col-s-10 col-m-7 col-l-7 col-xl-7">
                        <div class="top-nav">
                                <label for="toggle">&#9776;</label>
                                <input type="checkbox" id="toggle" />
                                <div class="menu">
                                        <a [routerLink]="'/contact'" [ngClass]="{ 'active-item': activeLink=='contact' }">Contact</a>
                                        <a [routerLink]="'/resume'" [ngClass]="{ 'active-item': activeLink=='resume' }">Résumé</a>
                                        <a [routerLink]="'/testimonials'"  [ngClass]="{ 'active-item': activeLink=='testimonials' }">Testimonials</a>
                                        <a [routerLink]="'/caseStudies'" [ngClass]="{ 'active-item': activeLink=='caseStudies' }">Case Studies</a>
                                        <a [routerLink]="'/home'" [ngClass]="{ 'active-item': activeLink=='home' }">Home</a>
                                </div>
                        </div>
                </div>
                <div class="col-xs
                col-s
                col-m
                col-l
                col-xl"> </div>
        </div>
</nav> 
