<app-navigation [activeLink]="'caseStudies'"></app-navigation>

<app-page-header [pageTitle]="'Case Studies'" [subHead]="''" [sectionImage]="false">
</app-page-header>

<app-info-section [subHead]="'Information Architecture, UX Design'" [sectTitle]="'Sales Tool Design'" [sectOrientation]="'cardLeft'" themeColor="blue" [imagePath]="'/assets/images/sales_enablement_preview.png'">
    We engaged our client to redesign an in-store sales enablement application for their store employees. The design needed to be able to accommodate sales people who helped complete 100+ item orders, relationship professionals who made sure the orders were accurately and effectively filled, managers who were solely responsible for the store’s key performance metrics, and customer service representatives.
    <br /><br />
    <a [routerLink]="'/sales-planning-tool'"><button class="btn-primary blue"> View Project Details </button></a>
</app-info-section>

<app-info-section [subHead]="'User Experience Research'" [sectTitle]="'User Persona Development'" [sectOrientation]="'cardRight'" themeColor="blue" [imagePath]="'/assets/images/persona_section_preview.png'">
    Prior to this work, NextGear had not completed research on their user base to understand who uses the systems they provide to manage their short term car dealership loans. Originally, I conducted 9 phone interviews, where members of the product team joined the calls to hear more about the dealers and their daily processes. 
    <br /> <br />
    Stemming from that small sample of work, the product team was convinced to invest in and conduct a deep dive user study to generate user personas.
    <br /> <br />
    In the duration of this study, we interviewed 28 dealership owners and staff members across 4 cities which we used to develop 4 personas.

    <br /> <br />
    <a [routerLink]="'/user-persona-development'"><button class="btn-primary blue"> View Project Details </button></a>
</app-info-section>

<app-info-section [subHead]="'UI Design'" [sectTitle]="'Material Redesign'" [sectOrientation]="'cardLeft'" themeColor="blue" [imagePath]="'/assets/images/material_redesign_preview.png'">
    The original apps that the NextGear team built had low internal user adoption. The UX team was tasked with delivering a Design System and UI library for our developer’s use. 
    <br /><br />
    When building the design system, I took inventory of design components across four production apps, understood the development team’s skillset / architecture and coupled that with Brad Frost’s industry-leadign Atomic Design System approach. 
    <br /><br />
    <a [routerLink]="'/ng-material-redesign'"><button class="btn-primary blue"> View Project Details </button></a>
</app-info-section>

