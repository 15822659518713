<app-navigation [activeLink]="'testimonials'"></app-navigation>

<app-page-header [pageTitle]="'Testimonials'" [subHead]="''" [sectionImage]="false">
    <p><i>Leader. User Research Expert. High Business Acumen.</i></p>

    <p>These are some of the many terms my colleagues have used to describe me. I’ve included some of the testimonials below so you can hear from others about me.</p>
    
    <p>Want to know more? 
        <br /><br />
        <a href="mailto:webmaster@example.com"><button class="btn-primary blue"><i class="fa fa-envelope"></i> &nbsp; Send me an E-mail </button></a>
</app-page-header>

<app-page-subhead [themeColor]="'blue'">
    <h2>What My Colleagues Say</h2>
</app-page-subhead>

<app-testimonial-quote [authorName]="'KC Wolff-Ingham'" [authorTitle]="'SVP, Director of Commercial Experience Design & Service Design at Truist'" [imagePath]="'/assets/images/kc_wolff_ingham.png'" [themeColor]="'blue'">
    Wes worked in my UX team at Sparks Grove. He’s an exceptionally hard working team player, extremely conscientious and diligent. He is constantly striving to learn more and improve himself. I would hire him again tomorrow.
</app-testimonial-quote>

<app-testimonial-quote [authorName]="'Jonathan Whiteside'" [authorTitle]="'Head of DesignOps at Truist'" [imagePath]="'/assets/images/jonathan_whiteside.png'" [themeColor]="'blue'">
    Wes was a brilliant team member with incredible business acumen. He has rock solid understanding of user experience methodologies, user interaction and design principles. He is an accomplished UX researcher who gathers unbiased data to help solve complex design problems and influence product strategy. He can prototype and test designs quickly while also organizing a UX effort and managing the client relationship. His versatility enables him to design successful solutions for enterprise SaaS, mobile apps and omni-channel marketing. 
</app-testimonial-quote>
