<app-navigation [activeLink]="'home'"></app-navigation>

<app-page-header 
    [pageTitle]="'Hello. I\'m Wes.'" 
    [subHead]="'Designer. Leader. Entrepreneur'"
    [sectionImage]="true">
    <p>I am a <i><b>user experience design leader</b></i>. I have led the creation of two design systems, and helped establish two user research and usability testing processes. </p>
    <p>I am an <i><b>entrepreneur</b></i>. I lead User Experience and Technology teams through complex challenges and problems through the use of design systems, good design process, and elegant design aesthetic. </p>
    <p>I am a <i><b>teacher</b></i>. I have mentored 50+ new UX Designers and Software Engineers.</p>
    <p>I am a <i><b>designer</b></i>.  I specialize in creating simple interfaces and experiences for B2B web applications and marketing websites. </p>
    <p>Want to know more? <a [routerLink]="'/contact'">Let's Chat</a></p>
</app-page-header>

<app-info-section [subHead]="'Research Focused'" [sectTitle]="'Analytical and Creative'" [sectOrientation]="'cardLeft'" themeColor="blue" [imagePath]="'/assets/images/ux_design_process.svg'">
    I'm a <b><i>Full Stack UX Designer</i></b> who prototypes and designs based on principles of behavioral econcomics and psychology to learn about the context, emotions, and ecosystems of users.
    <br /><br />
    Some of my favorite research techniques to uncover user needs, test hypotheses, and validate assumptions are: guerrilla usability testing, card sorting, contextual inquiry, ethnographic research, and competitive reviews with usability testing.
    <br /> <br />
    My process starts with <i>research leading into blue sky thinking and validation</i>. From there, I funnel my designs through usability testing. The testing allows me to refine concepts while always keeping the pulse of my users.
    <br /><br />
    <a [routerLink]="'/caseStudies'"><button class="btn-primary blue"> View Case Studies </button></a>
</app-info-section>


<app-info-section [subHead]="'Resume'" [sectTitle]="'Big Brand Experience'" [sectOrientation]="'cardRight'" [imagePath]="'/assets/images/brand_logos.png'" themeColor="orange">
    I have been blessed with the opportunity to work with colleagues spanning across many of the Fortune 500  brands and industries. A handful of the amazing brands I worked with in the past are Wells Fargo, Merrill Lynch, The Home Depot, Nissan, Equifax, McKesson, AT&T, Marathon Petroleum, and Eli Lilly and Co.
    <br /> <br />
    Unfortunately, a lot of my work for these brands is under NDA. If you want to learn more about my experience outside of my case studies, please contact me.
    <br /> <br />
    <a [routerLink]="'/resume'"><button class="btn-primary orange"> View My Resume </button></a>
    &nbsp;
    <a [routerLink]="'/contact'"><button class="btn-secondary orange"> Contact Me </button></a>
</app-info-section>


