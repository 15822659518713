<app-navigation [activeLink]="'caseStudies'"></app-navigation>
<app-portfolio-item-header [themeColor]="'orange'" [pageTitle]="'User Persona Development'">
    <span header>As an organization, NextGear had not completed user research on our dealer base to make better user first product decisions. Auto dealers can be very complex in their needs, and our strategy team kept putting features into the backlog that our dealers didn’t want and rarely used. In this project scope, we interviewed 28 dealers within a two hour radius of Indianapolis, Indiana.</span>
    <span role>UX Research Lead (25 interviews)<br />Visual Designer<br />Developed Interview Guide<br />Facilitated and Participated in synthesis</span>
    <span details>Who: NextGear Capital<br /> When: May 2017-July 2017</span>
</app-portfolio-item-header>

<div class="row p_i_body">
    <div class="col-xs col-s col-m col-l col-xl"> </div>
    <div class="col-xs-8 col-s-8 col-m=8 col-l-8 col-xl-8"> 
        <div class="row">
            <div class="col-xs-12 col-s-12 col-m-12 col-l-12 col-xl-12">
                <h2>Study Design</h2>
                Within the study, we leveraged the account managers at NextGear. As a part of the study, I built relationships with the account managers who coordinated 28 different dealers across four cities. Within this study we discussed daily processes, pain points, and other activities within the automotive dealer setting. One thing we noted was the constant changing of contexts. Dealers might have been at the dealership, on the road, or at an auction.
                <br /><br />
                <img src="../../../assets/images/ng_personas/ng_research_images.png">
                
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-s-12 col-m-12 col-l-12 col-xl-12">
                <h2>Persona Designs</h2>
                Each persona is made up of three pages to describe who they are. These pages help the technology team to empathize with our user base, have better conversations, and ultimately build better products.
            </div>
        </div>
        <div class="row image_half">
            <div class="col-xs-12 col-s-12 col-m-6 col-l-6 col-xl-6">
                <img src="../../../assets/images/ng_personas/main_slide.png" />
            </div>
            <div class="col-xs-12 col-s-12 col-m-6 col-l-6 col-xl-6">
                The first page of each persona is general information. It helps us understand who this person is and what their story entails. I chose vivid imagery to bring a feeling like the persona was looking directly at the reader, creating a more human connection.
            </div>
        </div>
        <div class="row image_half">
            <div class="col-xs-12 col-s-12 col-m-6 col-l-6 col-xl-6">
                <img src="../../../assets/images/ng_personas/second_slide.png" />
            </div>
            <div class="col-xs-12 col-s-12 col-m-6 col-l-6 col-xl-6">
                The second page helps the reader understand some of the abilities our users have. The character traits ultimately drive how a user will interact with different technologies / services.
            </div>
        </div>
        <div class="row image_half">
            <div class="col-xs-12 col-s-12 col-m-6 col-l-6 col-xl-6">
                <img src="../../../assets/images/ng_personas/third_slide.png" />
            </div>
            <div class="col-xs-12 col-s-12 col-m-6 col-l-6 col-xl-6">
                The third page represents fears, goals, and needs of the persona. This helped our teams determine if a solution concept works with our user or if it is a bit clunky. This page adds the human touch - helping the reader to better identify with the emotions of the users.
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-s-12 col-m-12 col-l-12 col-xl-12">
                <h2>Outcomes</h2>
                At the end of the day we had high adoption rates among development and product teams. Many times when discussing features our persona's names would come up in conversation. 
                <br/> <br/>Surprisingly, we drew a lot of interest from the Marketing and Customer Service departments. At the end of the day, our goal was to bring more empathy and reference to the end users of the system.<br/><br/>
            </div>
        </div>
    </div>
    <div class="col-xs col-s col-m col-l col-xl"> </div>

</div>

