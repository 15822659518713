import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ng-material-redesign',
  templateUrl: './ng-material-redesign.component.html',
  styleUrls: ['./ng-material-redesign.component.scss']
})
export class NgMaterialRedesignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
